import React from 'react';

class Chat extends React.Component {
  componentDidMount() {
    window.location.href = "https://servidorseguro.mysuite2.com.br/client/login.php?sl=exac&";
  }

  render() {
    return (
      <div>
        <h2>Chat</h2>
      </div>
    )
  }
}

export default Chat;